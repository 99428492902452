import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import Link from "../components/LocalizedLink";

import withLayout from "../layout";
import SEO from "../components/seo";

import { SEO_KEYWORDS } from "../constants";

import "./contact.scss";

const Success = (props) => {
    const { intl } = props;
    const titleText = intl.formatMessage({ id: "contact.success-title" });

    return (
        <div>
            <SEO
                title={titleText}
                keywords={SEO_KEYWORDS}
                lang={props.pageContext.locale}
            />
            <main className="contact contact--success" role="main">
                <div className="container">
                    <div className="pure-g-r">
                        <section className="pure-u-2-3">
                            <header role="banner">
                                <h1 className="h2 contact-title">
                                    <FormattedMessage id="contact.success-thanks" />
                                </h1>
                                <div className="contact-intro">
                                    <p className="f-copy">
                                        <FormattedMessage id="contact.success-response" />
                                    </p>
                                    <Link
                                        to="/"
                                        className="contact-success__cta"
                                    >
                                        <FormattedMessage id="contact.success-back" />
                                    </Link>
                                </div>
                            </header>
                        </section>
                    </div>
                </div>
            </main>
        </div>
    );
};

const customProps = {
    localeKey: "contact",
};

export default withLayout(customProps)(injectIntl(Success));
